import React from 'react'
import autogiro from '../images/bankid-screenshot.png'
import Layout from '../components/layout'

import AutogiroBrevPDF from '../images/autogirobrev-Lantmannen.pdf'
const url =
  'https://www.mvh.bgonline.se/mandate/282f94f5-5945-4e8f-88a3-fa5dfc3743fc'

const Button = () => (
  <a
    class="button"
    style={{ color: '#d42a2a' }}
    target="_blank"
    rel="noreferrer"
    href={AutogiroBrevPDF}
  >
    Teckna autogiro
  </a>
)

const AutogiroPage = () => (
  <Layout articlelevel={100}>
    <>
      <div className="contentWrapper flex">
        <div
          className="content content--inverted"
          style={{ textAlign: 'left' }}
        >
          <h1>Teckna autogiro för din prenumeration</h1>
          <p>
            Att betala sin prenumeration via autogiro är ett smart sätt att
            slippa hålla ordning på avier och förfallodatum.
          </p>
          <p>
            Via vår{' '}
            <a style={{ color: '#d42a2a' }} href={url}>
              e-formulär
            </a>{' '}
            kan privatpersoner skicka in medgivande för debitering via autogiro.
            Du behöver ha BankID och ditt kundnummer.
          </p>
          <p>
            Betalar-/kundnummer (prenumerationsnummer) finner du på din faktura
            eller när du loggar in på mina sidor på lantmannen.nu. Har du inte
            tillgång till ditt betalar- eller kundnummer är du varmt välkommen
            att kontakta kundservice på tel 010-184 40 84 så hjälper vi dig
            vidare.
          </p>
          <p>
            Pris: 144 kr/månad
            <br />
            Dragningsdatum: 28:e i varje månad.
          </p>
          <p>
            <Button />
          </p>
          <p>
            <b>Instruktioner:</b>
            <p>
              1. Fyll i formuläret, ta del av och godkänn villkoren och klicka
              på signera.
              <br /> 2. Du får då upp en ruta för att välja hur du önskar
              signera medgivandet. Välj BankID eller Mobilt BankID och följ
              därefter instruktionerna för signering.
            </p>
          </p>

          <img
            style={{ width: '100%', maxWidth: '550px' }}
            src={autogiro}
            alt="bankid instruktion"
          />
          <p>
            <Button />
          </p>
        </div>
      </div>
    </>
  </Layout>
)

export default AutogiroPage
